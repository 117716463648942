.arc-team-card {
    padding-top: 55px;
    width: 280px;
    overflow: visible;
    margin: 0 20px;
}

.arc-team-card-frame {
    height: 470px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    text-align: center;
}

.arc-team-card-frame div {
    position: relative;
}

.arc-team-card-frame::before {
    content: '';
    position: absolute;
    left: 30%;
    bottom: 89%;
    width: 40%;
    height: 24%;
    border-radius: 100%;
    box-shadow: 0px 200px 0px 300px var(--team-color);
}

.arc-team-image-frame {
    display: flex;
    justify-content: center;
    width: 100%;
    top: 2px;
    position: absolute;
}

.arc-team-image {
    display: block;
    margin: auto;
    left: calc(2/7*100%);
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background-size: cover;
}

.arc-team-content {
    z-index: 9;
    text-align: center;
    position: relative;
}

.arc-team-name {
    width: 100%;
    overflow: hidden;
    font-weight: bold;
    font-size: 30px;
    background: rgba(0, 0, 0, 0.4);
    padding: 5px;
}

.arc-team-tasks {
    padding: 10px;
    font-size: small;
    line-height: 15px;
    background: rgba(0, 0, 0, 0.2);
}

.arc-team-task {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 0
}

.arc-team-task-icon {
    fill: white;
    width: 10%;
    text-align: right;
}

.arc-team-task-name {
    width: 75%;
    text-align: left;
    padding-left: 10px;
}

.arc-team-desc {
    margin: 20px;
    padding: 20px 0 0 0;
    text-align: center;
    line-height: 30px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 25px;
    border-style: solid;
    border-width: 2px 0 0 0;
    height: 32%;
    position: relative;
    display: flex;
    align-items: center;
}

.arc-team-desc::before {
    font-family: Catamaran;
    font-size: 60px;
    content: '"';
    font-style: normal;
    background: var(--team-color);
    display: block;
    width: 40px;
    height: 15px;
    top: -5px;
    line-height: 45px;
    left: calc(50% - 20px);
    position: absolute;
}

.arc-team-quote {
    font-style: italic;
}

.arc-team-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 15px;
    background: rgba(0, 0, 0, 0.2);
}

div.arc-team-twitter {
    position: absolute;
    left: 10%;
    top: 30%;
}

div.arc-team-discord {
    position: absolute;
    right: 10%;
    top: 30%;
}

.arc-team-socials {
    position: absolute;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    width: 100%;
    height: 70px;
    fill: rgba(255, 255, 255, 0.2);
    transition: fill 1s;
}

.arc-team-socials :hover {
    fill: rgba(255, 255, 255, 0.6);
}
.arc-net-cards {
    height: 320px;
}

.arc-net-card {
    position: absolute;
    filter: drop-shadow(4px 4px 6px #111);
}

#arc-net-card-front {
    top: 48px;
    left: 20%;
    transform: rotate(8deg);
}

#arc-net-card-mid {
    top: 0px;
    left: 0;
    filter: drop-shadow(4px 4px 6px #111) brightness(0.7);
}

#arc-net-card-back {
    transform: rotate(-8deg);
    left: -20%;
    top: 24px;
    filter: drop-shadow(4px 4px 6px #111) brightness(0.4);
}

.arc-lqd-particles-bg-wrap {
    background-color: black;
    clip-path: url(#network-mask);
}

@media (min-width:768px) and (max-width: 991px) {
    .arc-col-md {
        width: 60%;
        left: 10%;
    }
}

@media (max-width:767px) {
    .arc-col-md {
        width: 67%;
        left: 14%;
        height: 240px;
    }
}

#network {
    position: relative;
    top: -40px;
}

#maps {
    position: relative;
    top: -14px;
}

#team {
    position: relative;
    top: -20px;
}
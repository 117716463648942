#content {
    background-image: none;
}

@font-face {
    font-family: Catamaran;
    src: local(Catamaran), url('../../fonts/Catamaran.ttf') format('opentype');
}

@media (min-width: 1200px) {
    .main-header .is-stuck {
        background: #17171a !important;
    }
}

.mainbar-wrap.is-stuck .social-icon a, .mainbar-wrap.is-stuck .header-module .ld-module-trigger, .mainbar-wrap.is-stuck .main-nav>li>a, .mainbar-wrap.is-stuck .ld-module-search-visible-form .ld-search-form input {
    color: rgba(255, 255, 255, 0.7) !important;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 1.9em;
    color: #cfcce8;
    background-color: #17171a;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6 .h6 {
    font-family: 'Poppins', 'sans-serif';
    font-weight: 700;
    color: #d6d9e1;
}

#content {
    z-index: 2;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    background-color: #1c1c20;
}

.main-nav>li>a {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: #c7cce1;
}

html {
    width: 100vw;
}

.bt-fade-white-015 {
    border-top: none;
}

.main-footer {
    color: inherit;
    background-color: #17171a;
}

@media screen and (max-width: 1199px) {
    .main-header .navbar-header {
        background-color: #17171a !important;
    }
}

.mainbar-wrap.is-stuck>.mainbar-container>.mainbar {
    height: 68px;
}

.logo-default, .logo-sticky {
    max-height: 87px;
    width: 55px;
    padding: 16px 0;
}

.mobile-logo-default {
    height: 40px;
}

.main-header .navbar-brand {
    padding: 5px;
}

.logo-sticky {
    max-height: 68px;
    width: 38px;
    padding: 0;
}
.is-stuck .navbar-brand {
    padding: 0;
}

.nav-trigger .bar {
    background-color: #fff;
}

.arc-footer {
    padding: 30px 0 10px 0 !important;
}

.mb-40 {
    width: 100px;
    margin: auto;
}

#arc-dhighlight {
    color: #7289DA;
}

#arc-dhighlight:hover {
    color: #fe688b;
}

.ld-fh-txt {
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(28, 28, 32, 0.6);
}

.ld-masked-image {
    top: -58vh;
    left: 8vw;
}

.ld-masked-image figure {
    background-position: 100% 0%;
    background-size: unset;
    padding: 300px;
    height: 1000px;
    width: 1050px;
}

.diashow {
    position: absolute;
    transition: opacity 2s;
    counter-increment: delay;
}

.diashow.active {
    animation: dia 15s linear;
}

@keyframes dia {
    0% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 100% 0%;
    }
}

/* Map Preview */

.carousel-caption {
    position: absolute;
    bottom: 20px;
    padding: 10px 20px;
    font-size: 3.5em;
    z-index: 9;
    font-weight: bolder;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
}

.carousel-item.is-selected .carousel-content, .carousel-item.is-selected .carousel-background {
    opacity: 1;
}

.carousel-item.is-selected .carousel-dl-map {
    opacity: 0.7;
}

.carousel-content, .carousel-background {
    position: absolute;
    width: calc(100% - 30px);
    opacity: 0;
    transition: opacity .6s;
    margin: auto;
}

.carousel-content {
    opacity: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    height: 50%;
}

@media (max-width: 720px) {
    .carousel-content .cc-text {
        width: 100% !important;
        font-size: 0.8em !important;
        line-height: 1.2em !important;
    }
    .cc-video {
        display: none;
        width: 0 !important;
    }
    .cc-video * {
        display: none;
        width: 0 !important;
    }
    .carousel-caption {
        font-size: 2em;
        padding: 0px 10px;
    }
    .carousel-content h4 {
        margin-top: 10px !important;
        margin-bottom: 5px !important;
    }
    .cc-author {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .carousel-dl-map {
        font-size: 1em !important;
        padding: 0 10px !important;
    }
    .carousel-dl-map .arc-icon {
        width: 18px;
    }
    .carousel-dl-map span {
        margin-left: 5px !important;
    }
}

@media (max-width: 1600px) {
    .carousel-content .cc-text {
        font-size: 0.9em !important;
        line-height: 1.6em;
    }
    .carousel-content h4 {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.cc-video {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 33%;
    margin-right: 10px;
    position: relative;
    display: block;
    box-shadow: 0px 5px 10px black;
}

.cc-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cc-author {
    color: rgba(255, 255, 255, 0.8);
    font-style: italic;
    float: right;
    padding-right: 10px;
}

.carousel-background {
    width: calc(100% - 30px);
    z-index: 5;
    background-position: center;
    filter: blur(10px);
    height: 70%;
    background-size: 100%;
    mask: linear-gradient(black 0%, white 20%, white 80%, black 100%);
    mask-mode: luminance;
}

.carousel-content .cc-text {
    width: 60%;
    padding: 20px;
    font-size: 1em;
}

.carousel-dl-map {
    position: absolute;
    right: 35px;
    bottom: 20px;
    padding: 8px 16px;

    background-color: rgb(71, 156, 236);
    border-radius: 20px;
    z-index: 999;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    fill: white;
    color: white;
    font-size: x-large;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    
    opacity: 0;
}

.carousel-dl-map:hover {
    opacity: 1 !important;
    color: white;
}

.carousel-dl-map span{
    margin-left: 10px;
    margin-right: 2px;
}

@media (min-width:768px) and (max-width: 1199px) {
    .carousel-item {
        width: 90%;
    }
}

/*body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #17171a;
}*/

.mainbar-wrap.is-stuck .social-icon a:hover, .mainbar-wrap.is-stuck .main-nav>li>a:hover {
    color: #fe688b !important;
}

.full-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vid-container {
    /*position: relative;*/
    padding-top: 56.25%;
}

.vid-frame {
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    height: 80%;
}

.arc-footer-nav {
    text-align: right;
    border-width: 0 1px 0 0;
    border-style: solid;
}

.arc-footer-col {
    padding: 0 30px 20px;
}

.arc-footer-branding {
    display: flex;
    height: 70px;
    justify-content: space-evenly;
}

.arc-footer-branding-icon {
    max-height: 70px;
}

.carousel-item {
    position: inherit;
}
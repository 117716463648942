@font-face {
  font-family: 'Cerebri Sans';
  src: url("../../fonts/cerebri-sans.woff2") format("woff2"), url("../../fonts/cerebri-sans.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

:root {
  --color-primary: #4762ff;
  --color-secondary: #ff6363;
  --color-tertiary: #ac1de1;
  --color-gradient-start: #4762ff;
  --color-gradient-stop: #ff798e;
  --color-gradient-start-secondary: #0bccee;
  --color-gradient-stop-secondary: #ac1de1;
  --color-gradient-start-tertiary: #ffba83;
  --color-gradient-stop-tertiary: #ffa5cd;
}

body {
  font-family: 'Cerebri Sans', sans-serif;
  font-size: 16px;
  line-height: 1.9em;
  color: #828282;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
  font-family: 'neuzeit-grotesk', 'sans-serif';
  color: #060221;
}

.h1,
h1 {
  font-size: 50px;
  line-height: 1.4em;
}

.h2,
h2 {
  font-size: 44px;
  line-height: 1.4;
  letter-spacing: 0;
}

.h3,
h3 {
  font-size: 32px;
  line-height: 1.5em;
}

.h4,
h4 {
  font-size: 26px;
  line-height: 1.6em;
}

.h5,
h5 {
  font-size: 20px;
  line-height: 1.6em;
}

.h6,
h6 {
  font-size: 18px;
  line-height: 1.8;
}

.font-family-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-family-amiri {
  font-family: 'Amiri', sans-serif;
}

.main-header .social-icon {
  font-size: 17px;
}

.main-header .social-icon:not([class*=scheme-]) a,
.main-nav > li > a {
  color: rgba(255, 255, 255, 0.7);
}

.main-header .social-icon:not([class*=scheme-]) a:hover,
.main-nav > li > a:hover {
  color: #fff;
}

.main-nav > li > a {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.main-nav > li.is-active > a,
.main-nav > li.current-menu-item > a,
.main-nav > li:hover > a,
.main-nav > li > a:hover {
  color: #fff;
}

.nav-light .main-nav > li > a {
  color: rgba(255, 255, 255, 0.75);
}

.nav-light .main-nav > li > a .link-ext {
  background-color: #fff;
}

.nav-dark .main-nav > li > a {
  color: rgba(0, 0, 0, 0.75);
}

.nav-dark .main-nav > li.is-active > a,
.nav-dark .main-nav > li.current-menu-item > a,
.nav-dark .main-nav > li:hover > a,
.nav-dark .main-nav > li > a:hover {
  color: #000;
}

.main-footer {
  color: #98a2aa;
}

.main-footer a:not(.btn) {
  color: #98a2aa;
}

.main-footer a:not(.btn):hover {
  color: #fff;
}

@media (min-width: 1200px) {
  .main-header .is-stuck {
    background: #fff !important;
  }
  .mainbar-wrap.is-stuck .social-icon a,
  .mainbar-wrap.is-stuck .header-module .ld-module-trigger,
  .mainbar-wrap.is-stuck .main-nav > li > a,
  .mainbar-wrap.is-stuck .ld-module-search-visible-form .ld-search-form input {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  .mainbar-wrap.is-stuck .social-icon a:hover,
  .mainbar-wrap.is-stuck .main-nav > li > a:hover {
    color: #000 !important;
  }
  .megamenu-heading {
    color: #3b91f7;
  }
  [data-megamenu-bg-scheme=light] .lqd-custom-menu a {
    color: #3e3f42;
  }
  .megamenu .lqd-custom-menu a:hover {
    color: #3b91f7;
  }
}

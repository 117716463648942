:root {
  --color-primary: #fe688b;
  --color-secondary: #f9b851;
  --color-gradient-start: #fe688b;
  --color-gradient-stop: #000b96;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  line-height: 1.9em;
  color: #828282;
}

#content {
  /*background-image: url(../../demo/bg/creative-content-bg.png);*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
  font-family: 'Poppins', 'sans-serif';
  font-weight: 700;
  color: #000;
}

.h1,
h1 {
  font-size: 52px;
  line-height: 1.4em;
}

.h2,
h2 {
  font-size: 42px;
  line-height: 1.3em;
  letter-spacing: 0;
}

.h3,
h3 {
  font-size: 30px;
  line-height: 1.6em;
}

.h4,
h4 {
  font-size: 25px;
  line-height: 1.7em;
}

.h5,
h5 {
  font-size: 21px;
  line-height: 1.8em;
}

.h6,
h6 {
  font-size: 18px;
  line-height: 1.6em;
}

.btn-solid {
  background-color: #3d59e8;
  border-color: #3d59e8;
}

.btn-solid:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.main-nav > li > a {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #858694;
}

.main-nav > li.is-active > a,
.main-nav > li.current-menu-item > a,
.main-nav > li:hover > a,
.main-nav > li > a:hover {
  color: #fe688b;
}

.main-footer {
  color: #808291;
}

.main-footer a:not(.btn) {
  color: #808291;
}

.main-footer a:not(.btn):hover {
  color: #fe688b;
}

@media (min-width: 1200px) {
  .main-header .is-stuck {
    background: #000 !important;
  }
  .mainbar-wrap.is-stuck .social-icon a,
  .mainbar-wrap.is-stuck .header-module .ld-module-trigger,
  .mainbar-wrap.is-stuck .main-nav > li > a,
  .mainbar-wrap.is-stuck .ld-module-search-visible-form .ld-search-form input {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  .mainbar-wrap.is-stuck .social-icon a:hover,
  .mainbar-wrap.is-stuck .main-nav > li > a:hover {
    color: #000 !important;
  }
}
